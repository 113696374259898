import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import Map from "../elements/Map2";

const propTypes = {
    topOuterDivider: PropTypes.bool,
    topDivider: PropTypes.bool,
};

const defaultProps = {
    topOuterDivider: false,
    topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
    const classes = classNames(
        "site-footer center-content-mobile",
        topOuterDivider && "has-top-divider",
        className
    );

    return (
        <footer {...props} className={classes}>
            <div className="container">
                <div
                    className={classNames(
                        "site-footer-inner",
                        topDivider && "has-top-divider"
                    )}
                >
                    <div>
                        <Map />
                    </div>
                    <div className="footer-top space-between text-xxs">
                        <div className="footer-logo">
                            <Logo />
                        </div>
                        <div class="footer-center">
                            <div>
                                <p>
                                    <span>Spomenik bb, Sarajevo 71000</span> Otes - Ilidža Residence
                                </p>
                            </div>

                            <div>
                                <p>+387 61 700 877</p>
                            </div>
                        </div>
                        <FooterSocial />
                    </div>
                    <div className="footer-bottom space-between text-xxs invert-order-desktop">
                        <FooterNav />
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
